import React, { useEffect, useState } from "react";
import baseURL from "../../ApiWork/BaseUrl";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FormLabel, Table, Button, Modal, Alert } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
export default function AdminDashBoard() {
  const [messages, setMessages] = useState([]);
  // const [Launched, setLaunched] = useState();
  const [processAgent, setProcessAgent] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tabledata, setTabledata] = useState([]);
  const [launchAgent, setLaunchAgent] = useState({ name: "" });
  const [items, setitems] = useState([]);
  const [active, setactive] = useState(1);
  const [activeTeam, setactiveTeam] = useState("");

  const Messages = async (team, active) => {
    try {
		if(team===""){
			setMessages(null);
			return null;
		}
			
      const data = {
        team: team,
        limit: 10,
        page: active,
      };
      const resp = await baseURL.post("/fetch/all/messages", data);
      setMessages(resp.data.data.data);
      // console.log("first", resp.data.data.count);
      let dataCount = [];
      for (
        let number = 1;
        number <= Math.ceil(resp.data.data.count / 10);
        number++
      ) {
        dataCount.push(number);
      }
      setitems([...dataCount]);
    } catch (error) {
      // alert("api error ");
    }
  };

  const teams = [
    {
      value: "",
      name: "",
    },
    {
      value: "sales",
      name: "sales",
    },
    {
      value: "marketing",
      name: "marketing",
    },
    {
      value: "accounts",
      name: "accounts",
    },
  ];
  const ApiList = async (myTeam) => {
    const newTeam = myTeam || "";
    try {
      const resp = await baseURL.get(`/fetch/user/team?team=${newTeam}`);
      // console.log("first", resp);
      if (resp.status == 200) {
        setTabledata(resp.data.data);
        // console.log("hello data table ", resp.data.data);
      }
    } catch (error) {
      toast.error(" Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const LaunchAgent = async (id) => {
    try {
      const resp = await baseURL.post("/launchPhantomAgent", { user_id: id });

      // console.log(resp.data.status);

      if (resp.data.status != "error") {
        setLaunchAgent(resp.data);

        toast.success(resp.data.status, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (resp.data.status == "error") {
        toast.error("There is no user left to process for today", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        alert("daat");
      }
      // console.log("data launch  agent", resp.data);
    } catch (error) {
      toast.error(" Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const CreateAgent = async () => {
    try {
      const resp = await baseURL.post("/createPhantomAgent", launchAgent);
      console.log("status", resp.data.status);
      if (resp.data.status != "error catch" && !launchAgent.name == "") {
        setShow(false);
        setLaunchAgent(resp.data);
        toast.success("Successfully created agent", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLaunchAgent({ name: "" });
      } else if (resp.data.status == "error catch") {
        toast.error(
          "Your current plan allows for a maximum of 5 Phantoms, a limit that you've reached.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      toast.error("Something went wrong.!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const ProcessedAggent = async (id) => {
    try {
      const resp = await baseURL.post("/fetchPhantomAgentOutput", {
        user_id: id,
      });
      console.log("resp data", resp);
      console.log("first", resp.data.status);
      if (resp.data.status != "error") {
        setProcessAgent(resp.data);
        toast.success(resp.data.message_status, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (resp.data.status) {
        toast.error(resp.data.message_status, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      toast.error(" Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    ApiList();
  }, []);
  return (
    <>
      <div>
        <div class="container">
          {/* {console.log({ activeTeam })} */}
          <div class="row">
            <div class="col-8">
              <div>
                <label style={{ display: "inherit" }}>
                  <div style={{ textAlign: "left" }}>
                    <b>Please Select Your Team</b>
                  </div>
                  <select
                    onChange={(e) => {
                      const team = e.target.value;
                      setactiveTeam(team);
                      // ApiList(team);
                      setTimeout(() => {
                        Messages(team, 1);
                      });
                    }}
                    className="form-control "
                    style={{ width: "30%" }}
                    name="selectedFruit"
                  >
                    <option value="" disabled selected hidden>
                      Select You Team
                    </option>
                    {teams.map((item) => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <div class="col-4">
              <div>
                <Button
                  style={{ marginRight: "16px" }}
                  variant="primary"
                  onClick={handleShow}
                >
                  CreateAgent
                </Button>
                <Button
                  style={{ marginRight: "16px" }}
                  onClick={() => LaunchAgent()}
                >
                  Launch Agent
                </Button>

                <Button
                  style={{ marginRight: "16px" }}
                  variant="primary"
                  onClick={() => ProcessedAggent()}
                >
                  Process Agent
                </Button>
              </div>
              <div>
                <div class="col">
                  <Modal
                    show={show}
                    onHide={handleClose}
                    style={{ marginTop: "10%", width: "100%", height: "100vh" }}
                    animation={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <center>Create Agent</center>{" "}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <input
                        aria-label="cookie name"
                        className="form-control"
                        placeholder="please enter name"
                        type="text"
                        value={launchAgent.name}
                        onChange={(e) => {
                          launchAgent.name = e.target.value;
                          setLaunchAgent({ ...launchAgent });
                        }}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => CreateAgent()}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!messages && messages.length > 0 ? (
          <div
            style={{
              marginTop: "10%",
              width: "100%",
              maxWidth: "100%",
              overflow: "auto",
            }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th>user_id</th>
                <th>container_id</th>
                <th>createdAt</th> */}
                  <th>Person Name</th>
                  <th>Message From Me?</th>
                  <th>Last Reply Content</th>
                  <th>Read Status</th>
                  <th>Last reply Date time</th>
                  <th>Linkedin Url</th>

                  {/* <th>updatedAt</th> */}
                </tr>
              </thead>
              <tbody>
                {!!messages &&
                  messages.map((item, index) => (
                    <tr key={index}>
                      {/* <td>{item.user_id}</td>
                    <td>{item.container_id}</td>
                    <td>{item.createdAt}</td> */}
                      <td>{item.firstnameFrom + " " + item.lastnameFrom}</td>
                      <td>{item.isLastMessageFromMe ? "Yes" : "No"}</td>
                      {/* {console.log("item.isLastMessageFromMe", item.readStatus)} */}

                      {/* <td>{item.lastnameFrom}</td> */}
                      <td>{item.message}</td>
                      <td>{item.readStatus ? "Yes" : "No"}</td>
                      <td>{item.lastMessageDate}</td>
                      <td>{item.lastMessageFromUrl}</td>

                      {/* <td>{item.updatedAt}</td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination>
              {items.map((number) => (
                <Pagination.Item
                  onClick={() => {
                    setactive(number);
                    Messages(activeTeam, number);
                  }}
                  key={number}
                  active={number === active}
                >
                  {number}
                </Pagination.Item>
              ))}
            </Pagination>
            {/* {messages ? (
            <table className="table table-responsive">
          
             
            </table> */}
          </div>
        ) : (
          <>
            <h1>No Data Found</h1>
            <img src="assests/Nodata.png"></img>
          </>
        )}
        <div>
          {activeTeam ? null : (
            <table style={{ marginTop: "5%" }} className="table table-bordered">
              <thead>
                <tr>
                  <th>username</th>
                  <th>team</th>
                  <th>email</th>
				  <th>Last Synch Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tabledata &&
                  tabledata.map((item, index) => (
                    <>
                      {item.team == "Admin" ? null : (
                        <tr key={index}>
                          <td>{item.username}</td>
                          <td>{item.team}</td>
                          <td>{item.email}</td>
						  <td>{item?.date}</td>
                          <td>
                            {/* {console.log({ item })} */}
							
							
							<Link
                              style={{ marginRight: "3%" }}
                              className=" btn btn-primary"
                              to={`https://node-user-auth-chrome-ext.herokuapp.com/api/set/cookie?user_id=${item._id}`}
                              variant="primary"
                              target="_blank"
                            >
                              Add Cookie
                            </Link>
							
                            <Button
                              style={{ marginRight: "3%" }}
                              onClick={() => LaunchAgent(item._id)}
                            >
                              Launch Agent
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => ProcessedAggent(item._id)}
                            >
                              Process Agent
                            </Button>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <ToastContainer />
      </div>
    </>
  );
}
